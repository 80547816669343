import React from "react"

function UiUx({ ...props }) {
  return (
    <svg
      width={246}
      height={246}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M223.816 114.902s6.721-15.887.61-40.94c-6.111-25.052-34.214-26.277-58.056-29.328-23.842-3.05-25.658-18.332-51.316-20.167-25.657-1.835-47.65 14.057-51.935 30.553-4.285 16.497.61 25.053-17.712 37.884-18.322 12.832-36.059 23.828-28.728 47.66 7.33 23.833 25.663 23.828 28.718 39.715 3.055 15.886 24.443 35.424 49.495 43.384 25.053 7.961 42.16-14.071 65.377-12.831 23.218 1.24 45.22-.615 56.216-18.332 10.996-17.717-4.89-34.829-1.835-47.05 3.055-12.221 6.75-18.942 9.166-30.548Z"
        fill="url(#a)"
      />
      <path
        d="M75.662 147.257c5.186-2.567 8.982-4.652 14.115-7.164a1.942 1.942 0 0 0 .588-3.048l-11.922-13.526c-.695-.802-1.979-.909-2.78-.16-.802.695-.91 1.978-.16 2.78l10.21 11.601c-4.223 2.139-7.43 3.85-11.815 5.988-.962.481-1.39 1.657-.909 2.62.535 1.016 1.711 1.39 2.673.909ZM60.158 147.791c.428-1.016 0-2.138-1.016-2.566-4.49-1.978-7.806-3.582-12.03-5.507l9.731-12.029c.695-.855.535-2.085-.267-2.78-.855-.695-2.085-.535-2.78.267l-11.334 14.008c-.802 1.016-.428 2.512.748 3.047 5.24 2.299 8.982 4.17 14.382 6.576.962.428 2.085 0 2.566-1.016ZM72.615 156.72c1.016-.375 1.55-1.497 1.123-2.513-4.438-11.816-8.554-23.898-12.19-35.927-.32-1.016-1.39-1.604-2.46-1.337-1.015.321-1.603 1.39-1.336 2.459 3.636 12.136 7.806 24.273 12.297 36.195.428.962 1.55 1.497 2.566 1.123ZM23.001 92.885c-.053-2.46 2.085-4.598 4.972-5.026l73.726-9.784c.481-.053.909.214.962.642.054.427-.267.802-.695.855l-73.725 9.784c-2.086.267-3.583 1.764-3.53 3.529l.642 22.026c0 .428-.374.749-.802.749-.48 0-.855-.321-.855-.695l-.695-22.08Z"
        fill="#314E55"
      />
      <path
        d="m37.169 102.508 70.732-7.057c.481-.054.908.267.962.641.053.428-.267.749-.749.802-73.298 7.325-70.732 7.057-70.839 7.057-.427 0-.801-.267-.855-.641-.053-.374.267-.749.748-.802ZM69.354 89.89c-.054-.427.267-.801.748-.855l29.191-3.475c.481-.053.909.214.962.642.054.427-.267.802-.748.855l-29.191 3.475c-.481.053-.909-.214-.962-.641ZM88.493 90.692c.481-.053.856.268.91.696.053.427-.322.748-.75.801-5.506.428-6.308.482-6.362.482-.427 0-.801-.268-.855-.695-.053-.428.32-.749.749-.802l6.308-.482ZM34.977 97.91c-1.016 1.497-3.048 1.657-4.33.32-1.872-1.87-1.07-5.452 1.603-5.613 2.673-.214 4.17 3.154 2.727 5.293ZM37.81 95.023c-.32-3.1 2.94-4.544 4.865-2.62 1.872 1.871 1.07 5.453-1.604 5.614-.053 0-3.047-1.07-3.26-2.994ZM45.723 93.74c-.16-1.818.962-3.368 2.62-3.475 1.496-.107 3.047 1.07 3.26 3.047.161 1.818-.962 3.368-2.619 3.475-1.497.107-3.047-1.069-3.261-3.047Z"
        fill="#314E55"
      />
      <path
        d="M121.373 124.054c.642 13.686 1.177 27.426 1.497 41.113.054 3.208.107 6.522-1.336 9.516-.856 1.818-2.459 3.475-4.384 4.491-1.123.963-2.566 1.497-4.224 1.497-.641 0 3.368.321-68.166-6.148-.48-.054-.801-.428-.748-.802.053-.428.481-.695.909-.642l67.524 6.095c2.459.214 4.705-1.443 4.651-3.528 0-.054-2.833-94.042-2.78-93.775-.053-2.085-2.299-3.689-4.865-3.315l-3.047.428c-.481.053-.909-.214-.963-.641-.053-.428.268-.803.695-.856l3.048-.428c1.336-.16 2.62 0 3.796.535.053 0 .107.053.107.053.374.16 2.887.802 4.758 2.994 1.925 2.246 1.497 4.33 1.657 6.897.588 9.035.054-4.17 1.818 34.965"
        fill="#314E55"
      />
      <path
        d="M163.969 180.473a335.35 335.35 0 0 1-15.981 1.442c-3.191.205-6.485.386-9.573-.489-4.452-1.261-6.331-4.221-5.971-8.416.155-1.93.798-3.757 1.416-5.584-.026 2.059-.052 4.169.463 6.176.489 2.007 1.595 3.963 3.345 5.044 2.291 1.441 5.224 1.184 7.927.901 14.282-1.57 28.591-3.14 42.925-4.71.824-.077 3.449-.823 4.144-.463 1.801.927-3.372 1.776-4.144 1.956a253.284 253.284 0 0 1-7.669 1.57c-5.327 1.004-11.452 2.728-16.882 2.573Z"
        fill="#CCC"
      />
      <path
        d="M216.742 120.879c-.441-.026-.798.309-.798.721l-1.018 36.029c0 .412.33.746.77.746.413.026.798-.308.798-.72l1.018-36.029c0-.387-.33-.721-.77-.747ZM218.373 93.859c.051-2.469-1.78-4.625-4.308-4.989l-63.823-9.795a.728.728 0 0 0-.825.624c-.052.39.206.78.619.831l63.822 9.795c1.78.286 3.096 1.793 3.07 3.507l-.567 21.981c0 .415.309.753.722.753.387.026.748-.312.748-.727l.542-21.98Z"
        fill="#314E55"
      />
      <path
        d="m206.087 103.458-61.05-7.142a.697.697 0 0 0-.797.656c-.026.42.231.788.643.814 63.287 7.405 61.05 7.143 61.127 7.143.36 0 .669-.289.72-.657a.731.731 0 0 0-.643-.814ZM178.283 90.965a.712.712 0 0 0-.62-.797l-25.266-3.335c-.387-.05-.774.224-.826.597a.712.712 0 0 0 .62.797l25.266 3.335c.413.05.774-.2.826-.597ZM161.847 91.57c-.386-.029-.746.287-.798.747-.025.459.258.832.669.89 4.784.459 5.453.516 5.504.516.386 0 .695-.316.721-.746.025-.46-.258-.833-.669-.89l-5.427-.517ZM208.099 98.981c.885 1.467 2.657 1.621 3.777.335 1.641-1.853.938-5.378-1.407-5.584-2.344-.205-3.647 3.14-2.37 5.249ZM205.423 95.887c.288-3.06-2.562-4.525-4.261-2.57-1.647 1.85-.915 5.373 1.412 5.579.052-.026 2.692-1.08 2.849-3.009ZM198.531 94.6c.156-1.805-.835-3.327-2.27-3.456-1.305-.104-2.688 1.057-2.87 2.992-.157 1.806.835 3.328 2.27 3.457 1.33.129 2.687-1.032 2.87-2.993ZM165.211 151.49c.128-.156.179-.364.128-.572-.691-4.263-1.612-9.878-2.252-13.907a.737.737 0 0 0-1.202-.442c-3.48 3.041-6.781 6.031-10.209 9.072a.615.615 0 0 0-.23.39c-.742 3.276-.794 6.733-.922 10.113-.179-.052-.383-.13-.562-.182a.719.719 0 0 0-.896.39c-1.228 2.807-2.251 5.719-3.48 8.5-.153.39.026.832.384.988 3.096 1.3 7.036 2.911 9.953 3.717.051.026.128.026.179.026a.716.716 0 0 0 .665-.468c.973-2.573 1.971-6.265 2.943-8.864a.757.757 0 0 0 .025-.962c-.153-.208-.332-.26-.486-.312.845-1.092 1.714-2.158 2.533-3.171 1.126-1.352 2.277-2.756 3.429-4.316Zm-7.42 7.019c-2.431-.806-3.429-1.118-5.834-1.898.128-3.327.154-6.81.845-10.008 2.712-2.391 5.04-4.497 8.392-7.435l-2.38 5.953a.732.732 0 0 0 .41.962.712.712 0 0 0 .946-.416l2.047-5.095c.614 3.718 1.152 7.019 1.663 10.268-1.995 2.652-3.991 4.94-6.089 7.669Z"
        fill="#314E55"
      />
      <path
        d="M159.542 146.673c-1.056-.695-2.602-.361-3.298.694-.722 1.132-.335 2.675.747 3.344s2.603.308 3.298-.746c.644-1.08.31-2.624-.747-3.292ZM184.589 118.161c-4.16-2.68-9.597-2.264-13.324 1.145l3.523-7.936a1.672 1.672 0 0 0 1.404-1.483 1.717 1.717 0 0 0-1.455-1.925c-1.608-.208-2.553 1.821-1.353 2.966l-9.368 21.152c-.817 0-1.532.625-1.659 1.483a1.717 1.717 0 0 0 1.455 1.926c1.506.182 2.476-1.587 1.506-2.784l2.246-5.1c-.051 5.204 3.471 9.913 8.679 11.11 3.828.858 7.632-.416 10.235-3.044-6.075-3.929-6.815-12.541-1.889-17.51ZM204.595 138.917c.899.255 1.825-.281 2.082-1.173a1.69 1.69 0 0 0-1.028-2.015l-2.493-23.007c1.285-.893.771-2.857-.746-3.035-.925-.128-1.773.536-1.901 1.454a1.656 1.656 0 0 0 1.207 1.811l.874 8.162c-3.418-6.734-12.464-8.188-17.836-2.857 6.091 3.826 6.914 12.217 1.902 17.166 6.297 3.979 14.855.765 16.757-6.606l.771 7.039c-1.285.74-1.028 2.678.411 3.061Z"
        fill="#314E55"
      />
      <path
        d="M133.345 127.855c-.025.412-.437.85-.025.85l.154-3.967c-.54 13.575-1.029 27.123-1.286 40.698-.052 3.168-.078 6.465 1.157 9.401.746 1.778 2.11 3.426 3.782 4.457.952.953 2.213 1.493 3.654 1.493.54 0-2.907.31 58.841-6.078a.722.722 0 0 0 .643-.799c-.052-.412-.412-.695-.798-.644l-58.3 6.053c-2.136.206-4.065-1.391-4.014-3.503 0-.051 2.419-93.063 2.419-92.806.051-2.035 2.006-3.632 4.193-3.297l2.65.412c.412.052.772-.206.824-.618.051-.386-.206-.773-.618-.824l-2.65-.412a5.302 5.302 0 0 0-3.267.54c-.052.026-.078.026-.103.052-.309.18-2.496.799-4.117 2.962-1.672 2.241-1.261 4.276-1.415 6.826-.514 8.964-.026-4.147-1.569 34.593l-.129 3.4c-.386.026-.026.799-.026 1.211ZM130.257 85.924c-.695-7.412-5.199-14.257-11.709-17.86-.284-.154-.361-.489-.232-.772.154-.283.489-.36.772-.232 6.82 3.783 11.529 10.963 12.275 18.76a.573.573 0 0 1-.514.619.566.566 0 0 1-.592-.515ZM131.183 75.295a13.44 13.44 0 0 0-2.779-4.529c-.206-.232-.206-.592.026-.798.231-.206.592-.206.798.026a15.352 15.352 0 0 1 3.01 4.915.573.573 0 0 1-.334.721.574.574 0 0 1-.721-.335ZM128.661 171.801c.875 7.386 5.508 14.154 12.096 17.602a.56.56 0 0 1 .231.772.56.56 0 0 1-.772.232c-6.922-3.629-11.786-10.731-12.687-18.478-.026-.308.18-.592.489-.617.309-.052.592.154.643.489ZM127.941 182.429a13.777 13.777 0 0 0 2.882 4.478c.206.232.206.592-.026.798-.231.206-.592.206-.798-.026-1.338-1.39-2.393-3.062-3.113-4.838a.6.6 0 0 1 .308-.746c.309-.103.644.051.747.334Z"
        fill="#314E55"
      />
      <defs>
        <linearGradient
          id="a"
          x1={260}
          y1={-11}
          x2={80}
          y2={205}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5E95B5" stopOpacity={0.37} />
          <stop offset={1} stopColor="#D19821" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default UiUx
